import { createCommonConfig } from '@energywebfoundation/generic-green-proofs-config';
import { createFrontendConfig } from '@energywebfoundation/generic-green-proofs-config/frontend';
import { ManifestConfiguration } from '../entities';
import { AccountTypesDetails } from '../entities/account/config';
import { AccountRolesDetails } from '../entities/account/roles';
import { CompanyRolesDetails } from '../entities/company/roles';
import logoIconDarkSvg from './assets/logo-dark.svg?url';
import logoIconSvg from './assets/logo.svg?url';
import { CustomHomepage } from './components/homepage/Homepage';
import { rjsfConfiguration } from './components/rjsfComponents';
import { tables } from './tables';

export const frontendConfiguration = createFrontendConfig(createCommonConfig({ appName: 'EV Registry', themeName: 'blueViolet' }), {
  assets: {
    logoIconSvg,
    logoIconDarkSvg,
  },
  CustomHomepage,
  rjsfConfiguration,
  tables,
  manifest: ManifestConfiguration,
  AccountRolesDetails,
  AccountTypesDetails,
  CompanyRolesDetails,
  helpDeskEmail: 'helpdesk@ev.co',
  TermsAndConditionsPage: () => null,
  envConfigPayload: {
    auth0: {
      domain: process.env.AUTH0_DOMAIN!,
      clientId: process.env.AUTH0_CLIENT_ID!,
      authAudience: process.env.AUTH_AUDIENCE!
    },
    sentry: {
      dsn: process.env.FRONTEND_SENTRY_DSN!,
      env: process.env.SENTRY_ENV!
    }
  }
});
